import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">

                <path fillRule="evenodd" clipRule="evenodd" d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z" />
                <path d="M117.141 19.7604L117.165 19.6731C117.895 17.4282 119.972 16.3335 122.177 17.0474C123.787 17.571 124.984 18.7688 124.603 20.6409L123.2 20.1888C123.358 19.1337 122.819 18.4832 121.844 18.1659C120.369 17.69 119.163 18.507 118.624 20.165L118.6 20.2522C118.077 21.8626 118.466 23.2111 120.052 23.7268C121.463 24.1869 122.32 23.5205 122.7 22.4893L120.805 21.8784L121.146 20.8234L124.421 21.8864L124.199 22.5845C123.525 24.6708 121.804 25.5116 119.679 24.8215C117.252 24.0282 116.42 21.9816 117.141 19.7604Z" />
                <path d="M125.952 22.7035L125.992 22.6242C126.967 20.522 129.195 19.6652 131.329 20.6489C133.47 21.6405 134.247 23.8458 133.263 25.9559L133.224 26.0352C132.248 28.1374 130.084 29.0497 127.895 28.0422C125.698 27.0268 124.993 24.7819 125.952 22.7035ZM131.828 25.4085L131.868 25.3292C132.55 23.8537 132.232 22.3703 130.829 21.7198C129.425 21.0693 128.109 21.7198 127.387 23.2667L127.348 23.346C126.618 24.9167 127.102 26.3763 128.41 26.9792C129.774 27.6059 131.106 26.9713 131.828 25.4085Z" />
                <path d="M134.722 26.884L134.769 26.8047C135.991 24.8373 138.314 24.2583 140.312 25.4958C142.319 26.7412 142.818 29.0258 141.589 31.0011L141.541 31.0804C140.32 33.0477 138.052 33.6903 136.006 32.421C133.937 31.1439 133.516 28.8275 134.722 26.884ZM140.225 30.2871L140.273 30.2078C141.129 28.8275 141.002 27.3203 139.678 26.4953C138.362 25.6782 136.974 26.1621 136.078 27.6059L136.03 27.6773C135.118 29.1448 135.42 30.652 136.641 31.4136C137.918 32.2227 139.321 31.7467 140.225 30.2871Z" />
                <path d="M142.914 32.1275L142.97 32.0561C144.445 30.2157 146.776 29.9301 148.584 31.3739C149.908 32.4369 150.598 33.9838 149.575 35.5941L148.425 34.6739C148.949 33.7458 148.679 32.9446 147.87 32.302C146.657 31.3342 145.238 31.6674 144.151 33.0239L144.096 33.0953C143.033 34.4201 142.922 35.8162 144.215 36.8554C145.373 37.7835 146.411 37.4662 147.133 36.6412L145.579 35.3958L146.277 34.5311L148.965 36.6888L148.505 37.26C147.133 38.9735 145.23 39.148 143.485 37.7439C141.503 36.1494 141.447 33.952 142.914 32.1275Z" />
                <path d="M153.166 35.4752L154.221 36.5065L149.487 41.3216L151.897 43.6935L151.073 44.5344L147.615 41.1313L153.166 35.4752Z" />
                <path d="M158.178 40.5839L161.39 44.3519L160.502 45.1055L158.242 42.456L156.592 43.868L158.4 45.994L157.56 46.7159L155.752 44.5899L153.991 46.0892L156.37 48.8815L155.482 49.6351L152.144 45.7163L158.178 40.5839Z" />
                <path d="M165.014 49.2384L166.393 51.3802C167.353 52.8716 167.321 54.2757 165.926 55.18L165.886 55.2038C164.482 56.1081 163.198 55.4973 162.286 54.0694L161.683 53.1334L159.146 54.7675L158.345 53.53L165.014 49.2384ZM163.158 53.4665C163.689 54.2836 164.371 54.4661 165.109 53.9901L165.148 53.9663C165.941 53.4586 165.949 52.7923 165.434 51.999L164.855 51.0947L162.579 52.5622L163.158 53.4665Z" />
                <path d="M169.138 55.8861L170.352 58.2501C171.145 59.7969 171.073 61.209 169.638 61.9467L169.598 61.9705C168.496 62.5417 167.576 62.1371 166.902 61.3756L164.88 64.9215L164.15 63.5015L166.046 60.1936L165.618 59.3527L162.771 60.8203L162.097 59.5114L169.138 55.8861ZM167.092 59.9239C167.544 60.8044 168.155 61.0741 168.908 60.6854L168.948 60.6616C169.757 60.2491 169.773 59.5986 169.345 58.7657L168.805 57.7106L166.561 58.8688L167.092 59.9239Z" />
                <path d="M168.955 64.3742L169.034 64.3425C171.199 63.5175 173.396 64.4614 174.228 66.6588C175.069 68.8641 174.085 70.9821 171.913 71.8151L171.833 71.8468C169.669 72.6718 167.48 71.8071 166.624 69.5622C165.759 67.2855 166.814 65.1913 168.955 64.3742ZM171.278 70.4189L171.358 70.3872C172.88 69.8081 173.689 68.5309 173.134 67.0792C172.579 65.6276 171.191 65.1754 169.589 65.7862L169.51 65.8179C167.892 66.4367 167.226 67.817 167.734 69.1656C168.265 70.5538 169.669 71.0297 171.278 70.4189Z" />
                <path d="M176.282 72.9019L176.877 75.2341C177.551 77.9074 176.362 79.5971 174.126 80.1603L174.03 80.1841C171.794 80.7473 169.875 79.8509 169.193 77.1538L168.606 74.8454L176.282 72.9019ZM170.303 76.8365C170.74 78.5579 171.937 79.137 173.634 78.7007L173.721 78.6769C175.378 78.2564 176.187 77.249 175.727 75.4483L175.521 74.6233L170.097 75.9956L170.303 76.8365Z" />
                <path d="M173.467 82.3259L178.328 81.6516L178.534 83.1192L173.784 83.7776C172.349 83.9759 171.818 84.5233 171.976 85.6814C172.135 86.8158 172.722 87.3156 174.34 87.0855L178.994 86.435L179.2 87.9026L174.427 88.569C172.373 88.8545 171.088 87.9026 170.803 85.8242C170.533 83.8807 171.453 82.6115 173.467 82.3259Z" />
                <path d="M175.425 90.4966H175.513C177.892 90.4252 179.604 92.0196 179.668 94.2329C179.715 96.0495 178.851 97.4853 176.996 97.7153L176.956 96.2161C178.003 96.0177 178.534 95.4149 178.502 94.2725C178.463 92.8764 177.352 91.9959 175.576 92.0514H175.489C173.705 92.099 172.674 92.9874 172.713 94.4471C172.745 95.5418 173.324 96.3033 174.442 96.4302L174.482 97.874C172.571 97.6995 171.564 96.3668 171.516 94.4867C171.445 91.9959 172.991 90.568 175.425 90.4966Z" />
                <path d="M178.257 101.483L178.407 99.2781L179.565 99.3574L179.153 105.243L177.995 105.164L178.146 102.959L171.397 102.483L171.501 100.999L178.257 101.483Z" />
                <path d="M178.344 110.995L177.836 113.343C177.258 116.04 175.434 117.016 173.182 116.532L173.086 116.508C170.834 116.024 169.51 114.366 170.097 111.645L170.597 109.313L178.344 110.995ZM171.247 111.852C170.874 113.589 171.691 114.636 173.404 115.001L173.491 115.017C175.156 115.374 176.33 114.834 176.718 113.018L176.901 112.185L171.429 111.003L171.247 111.852Z" />
                <path d="M176.275 119.752L174.752 124.472L173.642 124.115L174.712 120.799L172.643 120.133L171.786 122.791L170.732 122.449L171.588 119.792L169.384 119.078L168.258 122.568L167.147 122.212L168.725 117.317L176.275 119.752Z" />
                <path d="M169.011 124.282L168.447 125.583C167.678 125.361 166.941 125.393 166.41 126.622C166.061 127.423 166.267 128.185 166.941 128.479C167.615 128.764 168.011 128.542 168.717 127.447C169.7 125.805 170.581 125.21 171.945 125.797C173.134 126.313 173.594 127.717 172.944 129.232C172.27 130.803 171.152 131.43 169.7 130.969L170.232 129.724C171.048 129.922 171.564 129.621 171.929 128.788C172.285 127.963 172.071 127.368 171.508 127.122C170.922 126.868 170.517 126.987 169.788 128.169C168.82 129.779 167.948 130.454 166.497 129.827C165.236 129.288 164.705 127.788 165.402 126.178C166.251 124.203 167.599 123.798 169.011 124.282Z" />
                <path d="M170.485 134.238L169.795 135.554L162.77 131.882L163.459 130.565L170.485 134.238Z" />
                <path d="M165.268 135.642L165.34 135.689C167.33 136.959 167.877 139.243 166.632 141.203C165.72 142.631 164.261 143.487 162.549 142.654L163.342 141.409C164.325 141.821 165.086 141.472 165.641 140.6C166.474 139.291 165.982 137.911 164.515 136.982L164.444 136.935C163.016 136.023 161.605 136.062 160.717 137.466C159.924 138.72 160.352 139.719 161.256 140.338L162.327 138.656L163.262 139.251L161.415 142.163L160.796 141.774C158.948 140.6 158.56 138.72 159.757 136.832C161.105 134.682 163.294 134.388 165.268 135.642Z" />
                <path d="M163.055 146.264L161.945 147.636L155.387 146.621L159.844 150.23L158.995 151.269L152.834 146.279L153.793 145.09L160.764 146.145L156.046 142.321L156.894 141.274L163.055 146.264Z" />
                <path d="M157.021 153.403L153.532 156.917L152.708 156.1L155.158 153.633L153.62 152.102L151.653 154.085L150.868 153.308L152.834 151.325L151.193 149.691L148.608 152.293L147.783 151.475L151.407 147.826L157.021 153.403Z" />
                <path d="M151.629 158.67L149.583 160.376C148.243 161.487 146.855 161.725 145.816 160.479L145.784 160.447C144.991 159.495 145.182 158.512 145.776 157.687L141.875 156.481L143.104 155.458L146.744 156.592L147.466 155.989L145.42 153.53L146.554 152.586L151.629 158.67ZM147.236 157.552C146.474 158.179 146.347 158.837 146.887 159.488L146.918 159.519C147.497 160.217 148.132 160.098 148.861 159.495L149.773 158.734L148.155 156.79L147.236 157.552Z" />
                <path d="M140.487 166.984L138.845 167.991L132.478 162.597L133.778 161.796L135.34 163.137L137.917 161.558L137.426 159.559L138.607 158.837L140.487 166.984ZM138.187 162.653L136.197 163.874L139.107 166.405L138.187 162.653Z" />
                <path d="M134.548 170.419L132.248 171.522C130.646 172.291 129.259 172.085 128.537 170.585L128.521 170.546C127.792 169.039 128.561 167.841 130.091 167.111L131.09 166.627L129.782 163.906L131.114 163.264L134.548 170.419ZM130.583 168.055C129.703 168.475 129.441 169.134 129.822 169.927L129.838 169.967C130.25 170.815 130.908 170.903 131.757 170.498L132.724 170.03L131.55 167.587L130.583 168.055Z" />
                <path d="M127.426 173.679L125.04 174.56C123.374 175.17 122.01 174.837 121.439 173.282L121.424 173.243C120.845 171.672 121.717 170.562 123.311 169.974L124.35 169.594L123.303 166.77L124.683 166.262L127.426 173.679ZM123.707 170.95C122.795 171.291 122.47 171.918 122.772 172.735L122.788 172.775C123.113 173.655 123.763 173.806 124.643 173.481L125.65 173.108L124.714 170.569L123.707 170.95Z" />
                <path d="M120.061 176.241L118.641 176.622L116.889 170.109L113.629 170.99L113.328 169.856L118.015 168.594L120.061 176.241Z" />
                <path d="M113.145 177.955L111.686 178.24L110.163 170.458L111.622 170.173L113.145 177.955Z" />
                <path d="M108.799 174.742L108.807 174.829C109.069 177.193 107.626 179.034 105.437 179.28C103.629 179.486 102.13 178.732 101.75 176.908L103.233 176.741C103.518 177.765 104.16 178.24 105.294 178.114C106.682 177.955 107.467 176.781 107.269 175.012L107.261 174.925C107.063 173.148 106.095 172.204 104.644 172.362C103.55 172.481 102.844 173.124 102.812 174.25L101.377 174.409C101.393 172.489 102.63 171.379 104.501 171.172C106.967 170.903 108.522 172.323 108.799 174.742Z" />
                <path d="M97.738 179.637L95.8111 179.668L93.0674 171.783L94.5978 171.76L95.2639 173.711L98.2931 173.663L98.8799 171.688L100.268 171.664L97.738 179.637ZM97.9601 174.742L95.6208 174.782L96.842 178.447L97.9601 174.742Z" />
                <path d="M90.172 178.264L92.3686 178.447L92.2734 179.605L86.3975 179.113L86.4926 177.955L88.6892 178.137L89.2522 171.395L90.7351 171.521L90.172 178.264Z" />
                <path d="M84.2092 178.78L82.7422 178.55L83.9554 170.72L85.4225 170.95L84.2092 178.78Z" />
                <path d="M81.2033 174.306L81.1795 174.393C80.6244 176.638 78.6023 177.915 76.3186 177.352C74.0268 176.789 72.8453 174.774 73.4004 172.513L73.4242 172.426C73.9793 170.181 75.93 168.864 78.2693 169.443C80.6244 170.022 81.7505 172.085 81.2033 174.306ZM74.915 172.775L74.8912 172.862C74.5026 174.441 75.0974 175.837 76.604 176.202C78.1107 176.567 79.2764 175.686 79.6808 174.028L79.7046 173.941C80.1169 172.259 79.3636 170.927 77.968 170.577C76.5168 170.228 75.3273 171.109 74.915 172.775Z" />
                <path d="M70.0159 175.472L68.3665 174.853L67.2563 168.317L65.2422 173.679L63.9893 173.211L66.7726 165.794L68.2 166.333L69.4053 173.274L71.5384 167.587L72.7913 168.055L70.0159 175.472Z" />
                <path d="M60.7216 166.635C60.3569 167.325 59.5163 167.603 58.8026 167.222C58.081 166.841 57.851 165.992 58.2158 165.302C58.5806 164.604 59.4053 164.326 60.1348 164.715C60.8485 165.096 61.0943 165.937 60.7216 166.635Z" />
                <path d="M55.8439 160.138L55.2016 161.098C55.0351 160.852 54.8527 160.622 54.4721 160.36C53.8773 159.955 53.2826 160.011 52.7354 160.82L49.6666 165.366L48.4375 164.533L51.5222 159.955C52.4817 158.536 53.7425 158.432 54.9558 159.249C55.4871 159.614 55.7091 159.884 55.8439 160.138Z" />
                <path d="M49.2951 159.154L46.1946 162.962L45.0447 162.026L48.0818 158.305C49.0017 157.179 49.0176 156.418 48.1056 155.68C47.2175 154.958 46.4483 154.99 45.4174 156.259L42.4438 159.9L41.2939 158.964L44.339 155.228C45.6474 153.617 47.2413 153.443 48.859 154.768C50.3973 156.013 50.5877 157.568 49.2951 159.154Z" />
                <path d="M43.4026 153.506L42.4035 152.499C42.8951 151.864 43.1409 151.166 42.1973 150.214C41.5788 149.595 40.7937 149.5 40.2783 150.016C39.7628 150.531 39.8184 150.991 40.5796 152.047C41.7374 153.57 41.9673 154.617 40.9127 155.664C39.9928 156.584 38.5179 156.481 37.3522 155.315C36.1469 154.101 35.9724 152.832 36.9398 151.65L37.8914 152.61C37.4077 153.3 37.487 153.887 38.1293 154.529C38.7637 155.164 39.3981 155.188 39.8342 154.76C40.2862 154.307 40.3259 153.887 39.5012 152.776C38.3672 151.277 38.0659 150.214 39.184 149.096C40.1593 148.128 41.7453 148.191 42.9823 149.429C44.5049 150.968 44.3859 152.372 43.4026 153.506Z" />
                <path d="M33.6096 149.381L35.0211 151.079L34.125 151.824L30.3584 147.295L31.2545 146.549L32.666 148.247L37.86 143.923L38.8116 145.066L33.6096 149.381Z" />
                <path d="M29.0655 145.494L28.2012 144.288L34.6402 139.664L35.5045 140.869L29.0655 145.494Z" />
                <path d="M26.5206 141.909L25.6166 140.401L28.8123 134.595L23.9038 137.538L23.2139 136.387L30.0018 132.31L30.7868 133.619L27.4167 139.806L32.6186 136.681L33.3085 137.831L26.5206 141.909Z" />
                <path d="M20.3511 130.628L19.3361 128.169C18.67 126.559 18.8683 125.163 20.3591 124.544L20.3987 124.528C21.5485 124.052 22.4287 124.536 23.0393 125.345L25.3548 121.982L25.9654 123.457L23.7927 126.598L24.1495 127.471L27.1073 126.249L27.6703 127.614L20.3511 130.628ZM22.7301 126.765C22.3574 125.853 21.7626 125.535 20.9855 125.853L20.9459 125.869C20.1053 126.218 20.0339 126.852 20.3908 127.725L20.8428 128.82L23.1821 127.86L22.7301 126.765Z" />
                <path d="M17.2739 122.814L16.8616 121.394L20.0969 120.45L19.1057 117.039L15.8704 117.983L15.458 116.556L23.0627 114.342L23.475 115.77L20.2397 116.714L21.2309 120.125L24.4663 119.181L24.8786 120.601L17.2739 122.814Z" />
                <path d="M18.7968 113.398L18.7096 113.414C16.4258 113.795 14.4592 112.439 14.0786 110.122C13.6901 107.79 15.0778 105.91 17.3695 105.529L17.4567 105.513C19.7405 105.132 21.715 106.41 22.1115 108.782C22.5 111.177 21.041 113.026 18.7968 113.398ZM17.7105 107.02L17.6232 107.036C16.0135 107.306 14.9747 108.401 15.2284 109.924C15.4822 111.447 16.7589 112.177 18.44 111.891L18.5272 111.875C20.2321 111.59 21.1599 110.368 20.9299 108.948C20.6841 107.481 19.4074 106.735 17.7105 107.02Z" />
                <path d="M13.238 102.007L13.127 100.079L20.8902 97.0253L20.9774 98.5484L19.0584 99.2861L19.225 102.308L21.2153 102.816L21.2946 104.196L13.238 102.007ZM18.1307 102.039L17.9958 99.7065L14.3878 101.071L18.1307 102.039Z" />
                <path d="M13 95.1451L13.1189 92.7415C13.2538 89.9809 14.8952 88.7196 17.2028 88.8386L17.3059 88.8466C19.6055 88.9655 21.1835 90.3855 21.0408 93.162L20.9219 95.5418L13 95.1451ZM19.8751 93.1461C19.9624 91.3771 18.987 90.4728 17.2424 90.3855L17.1552 90.3776C15.4503 90.2903 14.3798 91.0122 14.2846 92.8764L14.245 93.7252L19.8355 94.0028L19.8751 93.1461Z" />
                <path d="M13.6338 86.1416L14.3713 81.2391L15.5211 81.4137L14.9977 84.8565L17.1467 85.1817L17.567 82.4211L18.6613 82.5877L18.2489 85.3483L20.5406 85.6973L21.0878 82.0641L22.2376 82.2387L21.4684 87.3315L13.6338 86.1416Z" />
                <path d="M20.0654 80.5013L20.4064 79.121C21.2073 79.2162 21.9289 79.0655 22.2541 77.7645C22.4682 76.9157 22.143 76.1938 21.4294 76.0193C20.7157 75.8448 20.3588 76.1304 19.8513 77.3282C19.1456 79.1052 18.3685 79.8429 16.9252 79.478C15.6644 79.1686 14.9904 77.8518 15.3869 76.2494C15.7992 74.5914 16.7984 73.7902 18.3129 74.0044L17.9878 75.3133C17.1473 75.2498 16.6873 75.6306 16.4653 76.5111C16.2512 77.3837 16.5525 77.939 17.1473 78.0818C17.7737 78.2405 18.1464 78.058 18.6698 76.7729C19.3676 75.0277 20.113 74.2186 21.6514 74.5994C22.9836 74.9325 23.7449 76.3208 23.3246 78.0184C22.8091 80.1364 21.5404 80.7552 20.0654 80.5013Z" />
                <path d="M22.2304 69.6255C21.5088 69.3479 21.1203 68.5546 21.4057 67.7931C21.6991 67.0236 22.508 66.6983 23.2375 66.968C23.975 67.2457 24.3477 68.031 24.0622 68.8005C23.7767 69.5621 22.9679 69.9111 22.2304 69.6255Z" />
                <path d="M21.3662 59.908L22.5002 57.782C23.8007 55.3466 25.8148 54.9024 27.8528 55.9892L27.94 56.0368C29.97 57.1236 30.7868 59.075 29.4784 61.5342L28.3603 63.6363L21.3662 59.908ZM28.4317 61.0265C29.2643 59.4637 28.7647 58.2262 27.2263 57.4012L27.147 57.3616C25.6404 56.5603 24.3637 56.7587 23.4914 58.4007L23.087 59.1543L28.0272 61.788L28.4317 61.0265Z" />
                <path d="M25.7666 52.0388L28.5182 47.9218L29.4857 48.5722L27.5508 51.4677L29.3588 52.6735L30.913 50.3492L31.8329 50.9679L30.2787 53.2922L32.2056 54.5773L34.2435 51.5311L35.211 52.1816L32.3563 56.4574L25.7666 52.0388Z" />
                <path d="M32.84 48.7466L32.7687 48.6911C30.929 47.1759 30.6911 44.8516 32.0946 43.1461C33.2524 41.742 34.8463 41.2343 36.4005 42.2814L35.4489 43.4396C34.5291 42.9002 33.7361 43.0033 33.0145 43.8838C32.1264 44.9627 32.388 46.3589 33.752 47.4853L33.8233 47.5408C35.2031 48.6752 36.5591 48.6911 37.4869 47.5567C38.1847 46.7079 38.2482 45.756 37.4869 44.923L38.4068 43.8125C39.731 45.2086 39.6121 46.8666 38.4147 48.3182C36.8287 50.238 34.7194 50.2935 32.84 48.7466Z" />
                <path d="M36.0762 38.8149L39.597 35.3324L40.4138 36.1574L37.9397 38.6086L39.4701 40.1555L41.4526 38.1882L42.2297 38.9735L40.2472 40.9408L41.8728 42.5908L44.4818 40.0127L45.2985 40.8377L41.6429 44.455L36.0762 38.8149Z" />
                <path d="M41.5312 33.5158L43.0776 32.2942L48.0654 35.2451L46.305 29.7319L47.8354 28.5182L52.7519 34.7295L51.5942 35.6418L47.883 30.9456L49.7465 37.1014L49.3738 37.3949L43.7754 34.1901L47.4865 38.8863L46.4477 39.7113L41.5312 33.5158Z" />
                <path d="M50.3174 26.8681L52.728 25.3768C54.1158 24.52 55.2576 24.5359 56.0189 25.7655L56.0427 25.8051C56.455 26.4715 56.4788 27.1854 55.8603 27.8994C56.8991 27.5345 57.6366 27.7645 58.1917 28.6609L58.2155 28.7006C58.9926 29.954 58.5564 31.0963 57.0736 32.0165L54.4805 33.6189L50.3174 26.8681ZM54.3299 28.3119C55.1625 27.7963 55.2894 27.2965 54.8691 26.6064L54.8453 26.5667C54.4488 25.9241 53.9096 25.8765 53.1087 26.3763L52.0937 27.003L53.299 28.9465L54.3299 28.3119ZM56.3678 31.2073C57.2084 30.6838 57.359 30.073 56.9308 29.3749L56.907 29.3352C56.4788 28.6371 55.8762 28.5261 54.9484 29.0972L53.8144 29.7953L55.1466 31.953L56.3678 31.2073Z" />
                <path d="M57.3828 22.6797L61.8235 20.4823L62.3389 21.5294L59.2225 23.0763L60.182 25.0278L62.6878 23.7902L63.1795 24.7818L60.6737 26.0193L61.6966 28.0977L64.9875 26.4715L65.5029 27.5186L60.8957 29.7953L57.3828 22.6797Z" />
                <path d="M64.21 19.4432L66.692 18.4992C68.3176 17.8805 69.7053 18.1105 70.2842 19.6257L70.3 19.6653C70.7441 20.8235 70.2366 21.6882 69.4119 22.2831L72.7107 24.6947L71.2199 25.2579L68.1431 22.9971L67.2629 23.3303L68.3969 26.3209L67.0171 26.8445L64.21 19.4432ZM68.0004 21.9262C68.9203 21.5771 69.2612 20.998 68.9599 20.2127L68.944 20.173C68.6189 19.3242 67.9845 19.237 67.1043 19.5702L65.9942 19.9906L66.8902 22.3545L68.0004 21.9262Z" />
                <path d="M76.4141 23.3136L78.6265 19.5694C79.1816 18.6254 79.475 17.84 79.3006 17.1023C79.134 16.3804 78.6186 16.0155 77.8732 16.19C77.104 16.3646 76.7234 16.9198 76.8423 18.0384L75.526 18.3398C75.2405 16.6343 76.0335 15.4285 77.667 15.0557C79.2688 14.6907 80.4266 15.3888 80.7359 16.7533C80.9658 17.7607 80.5693 18.7682 79.8556 19.8629L78.5234 21.9333L81.8064 21.1797L82.0601 22.2903L76.4776 23.5675L76.4141 23.3136Z" />
                <path d="M82.8929 18.3085L82.8453 17.9119C82.5439 15.5241 83.67 13.8979 85.6524 13.6441C87.6428 13.3902 89.1257 14.6753 89.427 17.0393L89.4746 17.4359C89.7759 19.7999 88.7371 21.4658 86.6833 21.7275C84.685 21.9735 83.1942 20.6646 82.8929 18.3085ZM88.0076 17.6184L87.96 17.2376C87.7459 15.5638 86.9767 14.6357 85.8031 14.7864C84.6136 14.9371 84.0903 15.9684 84.3123 17.7136L84.3678 18.1023C84.5978 19.8871 85.3907 20.6963 86.5643 20.5456C87.7776 20.3948 88.2375 19.3953 88.0076 17.6184Z" />
                <path d="M90.9894 20.8552L93.8838 17.6107C94.6133 16.7936 95.0494 16.0797 95.0256 15.3261C95.0019 14.5804 94.5657 14.1282 93.8045 14.152C93.0194 14.1758 92.5357 14.6439 92.4326 15.7703L91.0845 15.81C91.1321 14.0806 92.1471 13.0494 93.8124 13.0018C95.4539 12.9542 96.453 13.8664 96.4927 15.2626C96.5244 16.2939 95.9376 17.2061 95.0256 18.1422L93.3128 19.9112L96.675 19.816L96.7068 20.9583L90.9814 21.1249L90.9894 20.8552Z" />
                <path d="M98.3323 17.1982L98.3561 16.8015C98.5147 14.3979 99.942 13.0176 101.932 13.1525C103.931 13.2873 105.144 14.8342 104.985 17.214L104.962 17.6107C104.803 19.9905 103.463 21.4184 101.393 21.2835C99.379 21.1487 98.1737 19.578 98.3323 17.1982ZM103.487 17.5075L103.51 17.1188C103.621 15.4371 103.051 14.3741 101.869 14.2948C100.672 14.2155 99.9579 15.1198 99.839 16.8809L99.8152 17.2775C99.6962 19.0703 100.323 20.0222 101.504 20.1015C102.717 20.1888 103.368 19.3003 103.487 17.5075Z" />
                <path d="M109.911 18.7768C110.061 18.0153 110.775 17.4996 111.568 17.6504C112.377 17.809 112.837 18.5468 112.694 19.3083C112.543 20.0778 111.837 20.5855 111.037 20.4347C110.236 20.284 109.76 19.5463 109.911 18.7768Z" />


            </svg>

        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




